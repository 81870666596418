<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar :title="title" left-arrow @click-left="$router.back()" >
        <template #right>
          <div @click="toHome()">
            <van-icon name="wap-home-o" size="18" />
            <div style="float: right; margin-left: 5px; font-size: 15px">
              首页
            </div>
          </div>
        </template>
      </van-nav-bar>
      </van-sticky>

      <div class="detail">
        <h2 style="text-align: center">{{ cloudDiskFile.name }}</h2>
        <van-row v-if="cloudDiskFile.fileType.indexOf('video') != -1">
          <van-col span="24">
            <div class="znvideo">
              <video-player
                class="video-player-box vjs-big-play-centered"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="true"
                customEventName="customstatechangedeventname"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @ready="playerReadied"
                style="object-fit: cover"
              >
              </video-player>
            </div>
          </van-col>
        </van-row>
        <van-row v-if="cloudDiskFile.fileType.indexOf('audio') != -1">
          <van-col span="24">
            <div class="znaudio">
              <div class="name">
                {{ currentAudioName || audioList[0].name }}
              </div>
              <audio-player
                ref="audioPlayer"
                :showPrevButton="false"
                :showNextButton="false"
                :isLoop="false"
                :showVolumeButton="false"
                :progress-interval="100"
                :audio-list="audioList.map((elm) => elm.url)"
                :before-play="handleBeforePlay"
                theme-color="rgb(157,29,34)"
              />
            </div>
          </van-col>
        </van-row>
        <van-row v-if="cloudDiskFile.fileType.indexOf('image') != -1">
          <van-col span="24">
            <div class="znimage">
              <img
                :src="
                  cloudDiskFile.privewFileUrl != undefined &&
                  cloudDiskFile.privewFileUrl != ''
                    ? cloudDiskFile.privewFileUrl
                    : cloudDiskFile.fileUrl
                "
              />
            </div>
          </van-col>
        </van-row>

        <van-row style="margin: 5px">
          <van-col span="12">
            <div class="product-proptext">
              文件大小: {{ FileSize(cloudDiskFile) }}
            </div>
          </van-col>
          <van-col span="12"
            >创建时间: {{ cloudDiskFile.creationTime }}</van-col
          >
        </van-row>

        <van-row style="margin: 5px">
          <van-button type="info" size="large" round block @click="downLoadFile"
            >下载原文件</van-button
          >
        </van-row>
      </div>
      <prenext :cloudDiskFile="cloudDiskFile"></prenext>
      <comment :CommentPage="CommentPage"></comment>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { saveAs } from "file-saver";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

import AudioPlayer from "@liripeng/vue-audio-player";

import "@liripeng/vue-audio-player/lib/vue-audio-player.css";

import {
  getCloudDiskInfo,
  getCloudDiskDownLoadFile,
  addCloudDiskDownLoadFileLog,
} from "@/api/clouddisk"; //var data2 = await getCloudDiskDownLoadFileByUrl(this.cloudDiskFile.fileUrl);
import Comment from "../components/comment";
import prenext from "./components/prenext";

export default {
  name: "clouddisk-detail",
  data() {
    return {
      loading: true,
      type: 100,
      title: "中酿资料库",
      cloudDiskFile: {},

      playerOptions: {
        // videojs options
        controlslist: "nodownload",
        preload: "auto",
        autoplay: false,
        muted: false,
        fluid: true,
        width: document.documentElement.clientWidth,
        language: "zh-CN",
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        //playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
          },
        ],
        // children:
        // {
        // controlBar:{
        //   // volumeControl: true,
        //   // timeDivider: true, //当前时间和持续时间的分隔符
        //   // durationDisplay: true, //显示持续时间
        //   // remainingTimeDisplay: false, //是否显示剩余时间功能

        // }},

        controlBar: {
          // 设置控制条组件
          /* 设置控制条里面组件的相关属性及显示与否
    'currentTimeDisplay':true,
    'timeDivider':true,
    'durationDisplay':true,
    'remainingTimeDisplay':false,
    volumePanel: {
      inline: false,
    }
    */
          /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
          children: [
            { name: "playToggle" }, // 播放按钮
            { name: "currentTimeDisplay" }, // 当前已播放时间
            { name: "progressControl" }, // 播放进度条
            { name: "durationDisplay" }, // 总时间
            {
              // 倍数播放
              name: "playbackRateMenuButton",
              playbackRates: [0.5, 1, 1.5, 2, 2.5],
            },
            {
              name: "volumePanel", // 音量控制
              inline: false, // 不使用水平方式
            },
            { name: "FullscreenToggle" }, // 全屏
          ],
        },

        //poster: "http://www.znpz.com.cn/site/site01410/upfile/202101/2021012261441545.jpg",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
      },

      //音频
      currentAudioName: "",
      audioList: [{}],

      CommentPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 5,
        },
      },
    };
  },
  components: {
    AudioPlayer,
    videoPlayer,
    Comment,
    prenext,
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.loading = true;
        this.initData();
        this.onLoad();
      }
    },
  },
  methods: {
    toHome(){
      this.$router.replace({ path: "/" });
    },
    // listen event
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },

    // player is ready
    playerReadied(player) {
      player;
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    handleBeforePlay(next) {
      // 这里可以做一些事情...
      this.currentAudioName =
        this.audioList[this.$refs.audioPlayer.currentPlayIndex].name;

      next(); // 开始播放
    },

    initData() {},
    async onLoad() {
      let para = this.$route.query.para;
      let aresult = await getCloudDiskInfo(para); //({ ...this.page })
      this.cloudDiskFile = aresult.data.data;
      this.CommentPage.filterData.itemId = this.cloudDiskFile.id;

      if (this.cloudDiskFile.fileType.indexOf("video") != -1) {
        this.playerOptions.sources = [];
        this.playerOptions.sources.push({
          type: "video/mp4",
          src: this.cloudDiskFile.fileUrl,
        });
      }
      if (this.cloudDiskFile.fileType.indexOf("audio") != -1) {
        this.audioList = [];
        this.audioList.push({
          name: this.cloudDiskFile.name,
          url: this.cloudDiskFile.fileUrl,
        });
      }
      this.loading = false;
    },
    FileSize(record) {
      var fileSize = Math.round(record.fileSize / 1024);
      var suffix = "KB";
      if (fileSize > 1000) {
        fileSize = Math.round(fileSize / 1000);
        suffix = "MB";
      }

      return fileSize + suffix;
    },

    async downLoadFile() {
      if (this.isWenXin() && this.isMobile()) {
        Toast("微信中下载受限，请点击右上角三个点，在浏览器中打开下载源文件");
        return;
      }
      Toast("正在下载...");

      await addCloudDiskDownLoadFileLog(this.cloudDiskFile.id);

      if (this.cloudDiskFile.fileUrl.indexOf(".aliyuncs.com") > 0) {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = this.cloudDiskFile.fileUrl.replace("http:","https:");
        link.download = this.cloudDiskFile.name;
        link.click();
      } else {
        var data = await getCloudDiskDownLoadFile(this.cloudDiskFile.id);
        saveAs(
          data.data,
          //"/api/CloudDisk/DownLoadFile?id=" + this.cloudDiskFile.id,
          this.cloudDiskFile.name
        );
      }
    },
    isWenXin: function () {
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
        return true; // 是微信端
      } else {
        return false;
      }
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },

    // 字符串转为ArrayBuffer对象，参数为字符串
    str2ab: function str2ab(str) {
      var buf = new ArrayBuffer(str.length * 2); // 每个字符占用2个字节
      var bufView = new Uint16Array(buf);
      for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
      }
      return buf;
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 10px 10px 20px;
}
.znaudio {
  padding-left: 25px;
  padding-right: 25px;
  /deep/ .name {
    text-align: center;
    line-height: 120px;
  }
}
.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.znvideo {
  /deep/ .vjs-poster {
    background-color: rgba(157, 29, 34, 0.9);
  }

  video::-internal-media-controls-download-button {
    display: none;
  }

  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }
}

.znimage {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>