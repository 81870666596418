<template>
  <div>
    <div class="collectionlist" v-if="preNextData.pre || preNextData.next">
      <div style="font-size: 15px; color: rgba(0, 0, 0, 0.5); line-height: 20px">
        收录于
      </div>

      <div class="collectionbox">
        <div class="collectionitem" @click="HandlerShowSameTypeArticleList()">
          <van-tag color="rgb(255 242 219)" text-color="#9d1d22" size="large">#{{ preNextData.parent.name }}#
          </van-tag><span class="collectioncount"></span>
        </div>
      </div>
      <div style="padding: 10px 0px">
        <van-row v-if="preNextData.pre && preNextData.next">
          <van-col span="11" @click="toDetail(preNextData.pre.id)">
            <div>
              <div style="line-height: 18px">
                <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"><!----></i>
                上一个
              </div>
              <div style="font-size: 16px">
                {{ preNextData.pre.name }}
              </div>
            </div>
          </van-col>
          <van-col span="2"></van-col>
          <van-col span="11" @click="toDetail(preNextData.next.id)">
            <div style="text-align: right">
              下一个
              <i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
            </div>
            <div style="font-size: 16px">
              {{ preNextData.next.name }}
            </div>
          </van-col>
        </van-row>
        <van-row v-else-if="preNextData.pre">
          <van-col span="2"></van-col>
          <van-col span="22" @click="toDetail(preNextData.pre.id)">
            <div>
              <div style="line-height: 18px">
                <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"><!----></i>
                上一个
              </div>
              <div style="font-size: 16px">
                {{ preNextData.pre.name }}
              </div>
            </div>
          </van-col>
        </van-row>
        <van-row v-else-if="preNextData.next">
          <van-col span="2"></van-col>
          <van-col span="22" @click="toDetail(preNextData.next.id)">
            <div>
              <div>
                下一个
                <i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
              </div>
              <div style="font-size: 16px">
                {{ preNextData.next.name }}
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <van-popup v-model="showSameTypeArticleList" closeable position="bottom" :style="{ height: '90%' }">
      <div style="padding: 50px 10px 20px 10px">
        <van-list v-model="upLoading" :finished="finished" @load="productonLoad" finished-text="没有更多了">


          <div class="van-clearfix">
            <div class="layout_wrapper" style="
              float: left;
              width: 50%;
              text-align: center;
              background-color: #f8f8f8;
              overflow: hidden;
            " @click="toDetail(item.id.toString())" v-for="item in sameTypeArticleList" :key="item.id.toString()">
              <div class="product-layout__item">
                <van-image lazy-load class="w100" fit="contain" style="max-height: 200px" :src="FileImageUrl(item)" />

                <div style="height: 42px">
                  <h3 style="display: inline-block; height: 21px">
                    {{ item.name }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-popup>
  </div>
</template>


<script>
import { getPreNext, getCloudDiskList } from "@/api/clouddisk";

export default {
  name: "cloudprenext",
  props: {
    cloudDiskFile: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {

      preNextData: {},
      showSameTypeArticleList: false,
      sameTypeArticleList: [],

      upLoading: false,
      finished: false,
      articleList: [],
      page: {
        sorterType: 0,
        current: 1,
        pageSize: 10,
        filterData: {

          folderID: "00000000-0000-0000-0000-000000000000",
          folderType: 0,

        },
        fromType: 1,
      },
    };
  },
  components: {
  },
  updated() {

  },
  created() {
    this.init();
  },
  watch: {
  },
  mounted() { },

  methods: {
    async init() {
      let aresult = await getPreNext(this.cloudDiskFile.id)
      this.preNextData = aresult.data.data;
      this.page.filterData.folderID = this.preNextData.parent.id;

    },
    FileImageUrl(item) {
      if (item.folder) {
        return "/image/filetype/FolderType.png";
      } else {
        if (item.privewFileUrl != undefined && item.privewFileUrl != "") {
          return item.privewFileUrl;
        } else {
          if (item.fileType.indexOf("image") >= 0) {
            return "/image/filetype/ImgType.png";
          } else if (item.fileType.indexOf("audio") >= 0) {
            return "/image/filetype/MusicType.png";
          } else if (item.fileType.indexOf("video") >= 0) {
            return "/image/filetype/VideoType.png";
          } else {
            return "/image/filetype/GeneralType.png";
          }
        }

        //video/mp4
      }
    },
    toDetail(id) {
      this.$router.push({
        path: "/clouddisk/detail",
        query: { para: id },
      });
    },

    async HandlerShowSameTypeArticleList() {
      this.showSameTypeArticleList = true;
    },
    async productonLoad() {
      if (this.page.current == 1) {
        this.sameTypeArticleList = [];
      }
      let aresult = await getCloudDiskList({ ...this.page });
      this.sameTypeArticleList.push(...aresult.data.data);
      this.upLoading = false;
      if (
        this.page.current * this.page.pageSize <
        aresult.data.total
      ) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}

.zntitle {
  height: 50px;
}

.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.product-layout__item {
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 50px;
  overflow: hidden;
  color: #333;
  background-color: #fff;
  margin: 2px;
}

.collectionlist {
  margin: 0 0 16px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #f7f7f7;
}

.collectionbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.collectionitem {
  margin-right: 12px;
  margin-top: 6px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}

.collectioncount {
  display: inline-block;
  color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  font-size: 15px;
  line-height: 20px;
  margin-left: 4px;
}
</style>